.services {
    position: relative;
    background: $blueBlack;
    color: $white;
    z-index: 2;

    &__top {
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 60px 150px;
        padding-bottom: 20px;

        @include media("<=tablet") {
            padding: 60px 100px;
            padding-bottom: 20px;
        }

        @include media("<=560px") {
            padding: 120px 40px;
            padding-bottom: 20px;
        }


        .title {
            font-size: 30px;
            font-family: 'Manrope', sans-serif;
            font-weight: 800;
            margin-bottom: 30px;
            color: #8d61ec;

            @include media("<=tablet") {
                font-size: 26px;
            }

            @include media("<=654px") {
                font-size: 22px;
            }
        }

        .sub {
            text-align: center;
            line-height: 1.3;
            font-size: 20px;
            color: $subText;
            margin-bottom: 50px;

            @include media("<=1040px") {
                font-size: 18px;
            }

            @include media("<=tablet") {
                font-size: 14px;
            }

            @include media("<=654px") {
                font-size: 12px;
            }

            br {
                @include media("<=560px") {
                    display: none;
                }
            }
        }

        form {
            width: 70%;
            min-width: 450px;

            @include media("<=560px") {
                width: 100%;
                min-width: 0;
            }

            .input-row {
                display: flex;
                justify-content: space-between;

                @include media("<=560px") {
                    flex-direction: column;
                }

                .side {
                    width: 45%;

                    @include media("<=560px") {
                        width: 100%;
                        margin-bottom: 50px;
                    }
                }
            }

            input,
            textarea {
                border: 0;
                border-radius: 0;
                box-sizing: border-box;
                border-bottom: 1px solid $white;
                background: transparent;
                outline: none;
                box-shadow: none;
                text-align: left;
                font-size: 14.5px;
                width: 100%;
                color: $white;
                padding: 0.6em;
                padding-left: 0;

                @include media("<=1040px") {
                    font-size: 14px;
                }

                @include media("<=560px") {
                    font-size: 12px;
                }

            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                box-shadow: 0 0 0 30px $blueBlack inset !important;
                -webkit-box-shadow: 0 0 0 30px $blueBlack inset !important;
                -webkit-text-fill-color: $white !important;
            }

            .textarea {
                margin-top: 90px;

                @include media("<=560px") {
                    margin-top: 0;
                }


                textarea {
                    resize: none;
                    line-height: 1.4;
                }
            }

            label {
                margin-top: 20px;
                display: block;
                text-transform: capitalize;
                color: $subText;
                font-size: 12px;
                margin-bottom: 5px;
            }

            button {
                @include button;
                background: transparent;
                border: 1px solid #8d61ec;
                display: block;
                margin: 0 auto;
                margin-top: 80px;
                position: relative;
                color: #8d61ec;

                &:hover {
                    color: $white;


                    &::after {
                        width: 100%;
                        left: 0;
                        right: unset;
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    width: 0;
                    top: 0;
                    bottom: 0;
                    left: unset;
                    right: 0;
                    height: 100%;
                    transition: all .2s linear;

                    z-index: -1;
                    background: #8d61ec;
                }
            }
        }
    }
}